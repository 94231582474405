<template>
    <div class="max-w-content mx-auto fade-in">
        <div
            class="py-mobile md:py-desktop testimonal-carousel"
            :class="[textClass]"
        >
            <NCarousel
                :class="{ 'pointer-events-none': slides.length <= 1 }"
                :show-dots="false"
                :show-arrow="slides.length > 1"
                :draggable="slides.length > 1"
            >
                <div
                    v-for="(child, index) in slides"
                    :key="index"
                    class="flex flex-col h-full justify-center gap-8 w-full text-center px-side"
                >
                    <img
                        v-if="child.logo.length > 0"
                        :src="child.logo[0].url"
                        :alt="child.logo[0].alt"
                        class="h-10 mx-auto"
                    />
                    <div
                        class="text-f5 font-bold max-w-3xl mx-auto whitespace-pre-line"
                    >
                        «{{ child.lead }}»
                    </div>
                    <div>
                        <img
                            v-if="child.bild.length > 0"
                            :src="child.bild[0].url"
                            :alt="child.bild[0].alt"
                            class="w-14 h-14 mx-auto rounded-full mb-4 object-cover"
                            :style="{
                                'object-position': focalPoint(child.bild[0]),
                            }"
                        />
                        <div
                            v-if="child.ueberschrift"
                            class="text-regular-600 font-bold"
                        >
                            {{ child.ueberschrift }}
                        </div>
                        <div
                            v-if="child.position || child.firma"
                            class="text-regular"
                        >
                            {{ child.position
                            }}{{ child.position && child.firma ? ", " : "" }}
                            {{ child.firma }}
                        </div>
                    </div>
                </div>
                <template #arrow="{ prev, next }">
                    <div class="custom-arrow arrows-centered">
                        <button
                            :aria-label="t('goToPreviousTestimonial')"
                            type="button"
                            class="custom-arrow--left pointer-events-auto"
                            :class="[borderClass, btnClass]"
                            @click="prev"
                        >
                            <i
                                :class="[
                                    CONFIG_OPTIONS.global.iconStyle,
                                    CONFIG_OPTIONS.global.leftIcon,
                                ]"
                            ></i>
                        </button>
                        <button
                            :aria-label="t('goToNextTestimonial')"
                            type="button"
                            class="custom-arrow--right pointer-events-auto"
                            :class="[borderClass, btnClass]"
                            @click="next"
                        >
                            <i
                                :class="[
                                    CONFIG_OPTIONS.global.iconStyle,
                                    CONFIG_OPTIONS.global.rightIcon,
                                ]"
                            ></i>
                        </button>
                    </div>
                </template>
            </NCarousel>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { NCarousel } from "naive-ui";
import {
    Module_Testimonial_BlockType,
    Module_TestimonialEintrag_BlockType,
} from "@gql-types/types.generated";
import { CONFIG_OPTIONS, t } from "@src/globals";
import { focalPoint } from "../helpers/style.helper.ts";

const props = defineProps<{ data: Module_Testimonial_BlockType }>();

const slides = props.data.children as Module_TestimonialEintrag_BlockType[];

const textClass = props.data.hintergrundFarbe
    ? "text-" + CONFIG_OPTIONS.testimonials.textColor
    : "text-black";
const borderClass = props.data.hintergrundFarbe
    ? "border-" + CONFIG_OPTIONS.testimonials.textColor
    : "border-black";
const btnClass =
    props.data.hintergrundFarbe &&
    CONFIG_OPTIONS.testimonials.bgColor === "bg-primary" // background is primary color
        ? "btn-round-primary-light"
        : "btn-round-primary-dark";
</script>

<style lang="scss" scoped>
.carousel-img {
    width: 100%;
    height: 240px;
    object-fit: cover;
}

.custom-arrow {
    @apply flex gap-4 h-max w-full flex-wrap px-side;
    @apply mt-4 justify-end;

    font-size: 1.25rem;

    @screen minM {
        @apply absolute top-1/2 transform -translate-y-1/2;
        @apply mt-0 justify-between;
    }
}
</style>

<style lang="scss">
.testimonal-carousel .n-carousel .n-carousel__slides .n-carousel__slide {
    height: auto;
}
</style>
